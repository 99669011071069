import { createSlice } from "@reduxjs/toolkit";
import { IAdvertising } from "api/types/_advertising";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { advertising } from "./thunk";

export interface IState {
  advertising: ResponseData<IAdvertising[]> & PaginationResponse | null,
  isAdvertisingLoading: boolean,
  isAdvertisingSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  advertising: null,
  isAdvertisingLoading: false,
  isAdvertisingSuccess: false,

  error: {},
};

const AdvertisingSlice = createSlice({
  name: "Advertising",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //advertising
    builder.addCase(advertising.pending, (state: IState, action: any) => {
      state.isAdvertisingLoading = true
    });
    builder.addCase(advertising.fulfilled, (state: IState, action: any) => {
      state.advertising = action.payload.data;
      state.isAdvertisingSuccess = true;
      state.isAdvertisingLoading = false;
    });
    builder.addCase(advertising.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdvertisingSuccess = false;
      state.isAdvertisingLoading = false;
    });
  },
});

export default AdvertisingSlice.reducer;