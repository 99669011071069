import { createAsyncThunk } from "@reduxjs/toolkit";
import advertisingApi from "api/advertisingApi";
import { formatQueryParams } from "helpers/format";

export const advertising = createAsyncThunk("Advertising", async (params: any = {}) => {
  try {
    const response = await advertisingApi.advertising(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllAdvertising = async (params: any = {}) => {
  try {
    const response = await advertisingApi.allAdvertising(params)
    return response;
  } catch (error) {
    return error;
  }
};