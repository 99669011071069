import React, { useState } from 'react';

const BackToTop = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);
    return (
        <React.Fragment>
            <button className="btn btn-danger btn-icon" id="back-to-top" style={{ bottom: '50px', display: visible ? 'block' : 'none' }} onClick={scrollToTop} ><i className="ri-arrow-up-line"></i></button>
        </React.Fragment>
    );
};

export default BackToTop;