import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard CRM
import DashboardReducer from "./dashboard/reducer";

// User
import UserReducer from "./user/reducer";

// Role
import RoleReducer from "./role/reducer";

// Global
import GlobalReducer from "./global/reducer";

// Report
import ReportReducer from "./report/reducer";

// Advertising
import AdvertisingReducer from "./advertising/reducer";


const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Dashboard: DashboardReducer,
    User: UserReducer,
    Role: RoleReducer,
    Global: GlobalReducer,
    Report: ReportReducer,
    Advertising: AdvertisingReducer,
});

export default rootReducer;