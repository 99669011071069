import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import dashboardApi from 'api/dashboardApi';
import { formatQueryParams } from "helpers/format";

export const getDashboard = createAsyncThunk("dashboard" , async (params: any = {}) => {
  try{
    const response = await dashboardApi.dashboard(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
})


