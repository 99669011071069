import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IAdvertising } from "./types/_advertising";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/advertising';
const advertisingApi = {
  advertising(params: any): Promise<AxiosResponse<ResponseData<IAdvertising[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  allAdvertising(params: any = {}): Promise<AxiosResponse<ResponseData<IAdvertising>, any>> {
    const url = `/masters/advertising`;
    return api.get(url, params)
  },
}
export default advertisingApi
