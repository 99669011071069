import * as React from "react";
const SVGImageDefault = (props: any) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.97 22.97"
        {...props}
    >
        <defs>
            <style>
                {
                    ".cls-1,.cls-2{fill:none;stroke:#898989;}.cls-1{stroke-width:0.5px;}.cls-2{stroke-width:0.7px;}"
                }
            </style>
        </defs>
        <title>{"status3"}</title>
        <g id="Group_1005" data-name="Group 1005">
            <line
                id="Line_67"
                data-name="Line 67"
                className="cls-1"
                x1={7.35}
                y1={0.53}
                x2={0.35}
                y2={7.53}
            />
            <line
                id="Line_68"
                data-name="Line 68"
                className="cls-1"
                x1={12.35}
                y1={0.53}
                x2={0.35}
                y2={12.53}
            />
            <line
                id="Line_69"
                data-name="Line 69"
                className="cls-1"
                x1={17.35}
                y1={0.53}
                x2={0.35}
                y2={17.53}
            />
            <line
                id="Line_70"
                data-name="Line 70"
                className="cls-1"
                x1={21.35}
                y1={1.53}
                x2={1.35}
                y2={21.53}
            />
            <line
                id="Line_71"
                data-name="Line 71"
                className="cls-1"
                x1={22.62}
                y1={5.18}
                x2={5.35}
                y2={22.53}
            />
            <line
                id="Line_72"
                data-name="Line 72"
                className="cls-1"
                x1={22.62}
                y1={10.53}
                x2={10.62}
                y2={22.53}
            />
            <line
                id="Line_73"
                data-name="Line 73"
                className="cls-1"
                x1={22.62}
                y1={15.53}
                x2={15.62}
                y2={22.53}
            />
            <path
                id="Path_32"
                data-name="Path 32"
                className="cls-2"
                d="M6.75,1.86s-4.89,0-4.89,4.89v12.5s0,4.89,4.89,4.89h12.5s4.88,0,4.88-4.89V6.75s0-4.89-4.88-4.89Z"
                transform="translate(-1.51 -1.51)"
            />
        </g>
    </svg>
);
export default SVGImageDefault;
