import React from 'react';
//import Scss
import './assets/scss/themes.scss';
//imoprt Route
import Route from './routes';

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
