import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useProfile } from "../components/Hooks/UserHooks";

import authApi from "api/authApi";
import { loginSuccess } from "store/auth/login/reducer";
import { useDispatch } from "react-redux";


const AuthProtected = (props: any) => {
  const { userProfile, loading } = useProfile();
  const dispatch: any = useDispatch();
  /*
    Navigate is un-auth access protected routes via url
    */

  if (!userProfile && loading) {
    return (
      <Navigate to={{ pathname: "/login" }} />
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const resProfile = async () => {
      try {
        const res = await authApi.profile(userProfile?.user?.id || '');
        if (res?.data) {
          const finalLogin = {
            ...userProfile,
            user: {
              ...userProfile?.user,
              ...res?.data
            }
          };
          localStorage.setItem("authUser", JSON.stringify(finalLogin));
          dispatch(loginSuccess(finalLogin));
        }
      } catch (error: any) {
        return error;
      }
    };
    resProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{props.children}</>;
};


export default AuthProtected;