import flagus from "../assets/images/flags/us.svg";
import flagkorea from "../assets/images/flags/kr.svg";

const languages = {
  en: {
    label: "English",
    flag: flagus,
  },
  ko: {
    label: "한국",
    flag: flagkorea,
  },
}

export default languages
