import React, { ReactNode, useEffect } from "react";

import { useNavigate } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const AppNavigationProvider: React.FC<Props> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  useEffect(() => {
    const navigationEventController = (e: any) => {
      const { detail = {} } = e;
      switch (detail.type) {
        case "push": {
          navigate(detail.path, { state: detail.state });
          break;
        }
        case "replace": {
          navigate(detail.path, { state: detail.state, replace: true });
          break;
        }
        case "goBack": {
          navigate(-1);
          break;
        }
        default:
          break;
      }
    };

    window.addEventListener("route", navigationEventController);

    return () => {
      window.removeEventListener("route", navigationEventController);
    };
  }, [navigate]);

  return <>{children}</>;
};

export default AppNavigationProvider;
