import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeDurationResponses } from "store/thunks";

const useDurationResponses = () => {
  const dispatch: any = useDispatch();
  const { durationResponses } = useSelector((state: any) => state?.Global);

  const setDurationResponses = useCallback((preloader: { name: string, time: number }[] | null) => {
    dispatch(changeDurationResponses(preloader));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(changeDurationResponses(null));
    }
  }, []);

  return { durationResponses, setDurationResponses };
};

export { useDurationResponses };
