import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IPermission, IRole } from "./types/_role";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/roles';
const roleApi = {
  roles(params: any):Promise<AxiosResponse<ResponseData<IRole[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  allRoles(params: any = {}):Promise<AxiosResponse<ResponseData<IRole[]>, any>>  {
    const url = `masters/roles`
    return api.get(url, params)
  },
  getRole(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IRole>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postRole(dataForm: any) : Promise<AxiosResponse<ResponseData<IRole>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putRole(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IRole>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteRole(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IRole>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  allPermissions(params: any):Promise<AxiosResponse<ResponseData<IPermission[]>, any>> {
    const url = `/permissions`
    return api.get(url, params)
  },
  currentPermissions(params: any):Promise<AxiosResponse<ResponseData<IPermission[]>, any>> {
    const url = `/permissions/current`
    return api.get(url, params)
  },
}
export default roleApi
