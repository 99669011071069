import { createSlice } from "@reduxjs/toolkit";
import { getDashboard } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IDashboard } from "api/types/_dashboard";


export interface IState {
  dashboard: ResponseData<IDashboard>  | null,
  isDashboardLoading: boolean,
  isDashboardSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  dashboard: null,
  isDashboardLoading: false,
  isDashboardSuccess: false,

  error: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get movies
    builder.addCase(getDashboard.pending, (state: IState, action: any) => {
      state.isDashboardLoading = true
    });
    builder.addCase(getDashboard.fulfilled, (state: IState, action: any) => {
      state.dashboard = action.payload.data;
      state.isDashboardSuccess = true;
      state.isDashboardLoading = false;
    });
    builder.addCase(getDashboard.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isDashboardSuccess = false;
      state.isDashboardLoading = false;
    });
  },
});

export default dashboardSlice.reducer;