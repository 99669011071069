interface ApiConfig {
  API_URL: string;
}

interface Config {
  api: ApiConfig;
  message_hub_sso_redirect_url: string;
}

const devMessageHubSSORedirectURL = process.env.REACT_APP_MESSAGE_HUB_SSO_REDIRECT_URL || 'https://dev-console.messagehub.click/sso-redirect';
const prodMessageHubSSORedirectURL = process.env.REACT_APP_MESSAGE_HUB_SSO_REDIRECT_URL || 'https://console.messagehub.click/sso-redirect';

const config: Config = {
  api: {
    API_URL: `${process.env.REACT_APP_API_URL}`,
  },
  message_hub_sso_redirect_url: process.env.NODE_ENV === 'development' ? devMessageHubSSORedirectURL : prodMessageHubSSORedirectURL,
};

export default config;