import { CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import React, { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
interface CopyWrapperProps {
    children: ReactNode;
    contentCopy?: string,
    style?: any
}

const CopyWrapper: React.FC<CopyWrapperProps> = ({
    children,
    contentCopy = '',
    style = {},
}) => {
    const { t } = useTranslation();
    const copyTextToClipboard = useCallback(async (text: string) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand('copy', true, text);
        }
        toast(`${t('Copied Successfully')}`, CONFIG_OPTION_TOAST_NORMAL);
    }, []);
    return (
        <div className="copy-text-to-clipboard-wrapper" style={{ ...style }}>
            <>{children}</>
            {!!contentCopy && <i className="ri ri-file-copy-line ps-1 text-primary cursor-pointer icon-copy" onClick={() => copyTextToClipboard(contentCopy)} />}
        </div >
    );
};

export default CopyWrapper;
