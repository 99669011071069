import { Option } from "api/types/_public";

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const ARR_INDEX_TEXT_DAY_OF_WEEK: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: 'scroll',
  MODAL: 'modal'
}

export const STATUS_ADVERTISER_OPTIONS: Option[] = [
  {
    label: "Status_Advertiser_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Advertiser_1", // Active
    value: "1",
  }
];
